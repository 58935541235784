<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl class="box">
            <!--<v-layout row wrap align-center  justify-center fill-height>-->
                <v-layout row wrap justify-center fill-height>
                
                <!-- RPI 1 = MAKEDAM (vhod, izhod) -->
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                        <v-card-title>
                            <v-icon left>mdi-car</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">Peščeno (dvig - spust zapornic)</span>
                                    <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card1')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','vh1_dvig', 56)" :disabled="loading1 || disabled" :loading="loading1" large class="dvig" block><v-icon left></v-icon>Vhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','vh1_spust',56)" :disabled="loading2 || disabled" :loading="loading2" large class="spust" block>Vhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','vh1_stalni_dvig',56)" :disabled="loading3 || disabled" :loading="loading3" large color="stalni_dvig" class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text v-show="card1">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','izh1_dvig',57)" :disabled="loading4 || disabled" :loading="loading4" large class="dvig" block><v-icon left></v-icon>Izhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','izh1_spust',57)" :disabled="loading5 || disabled" :loading="loading5" large class="spust" block>Izhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('makedam','izh1_stalni_dvig',57)" :disabled="loading6 || disabled" :loading="loading6" large class="stalni_dvig" block>Izhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
                 <!-- RPI 2 = HOTEL (vhod, izhod) -->
               <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                        <v-card-title>
                            <v-icon left>mdi-car</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">Hotel (dvig - spust zapornic)</span>
                                    <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card2')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card2"></v-divider>
                        <v-card-text v-show="card2">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','vh1_dvig', 54)" :disabled="loading7 || disabled" :loading="loading7" large class="dvig" block><v-icon left></v-icon>Vhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','vh1_spust', 54)" :disabled="loading8 || disabled" :loading="loading8" large class="spust" block>Vhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','vh1_stalni_dvig', 54)" :disabled="loading9 || disabled" :loading="loading9" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text v-show="card2">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','izh1_dvig', 55)" :disabled="loading10 || disabled" :loading="loading10" large class="dvig" block><v-icon left></v-icon>Izhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','izh1_spust', 55)" :disabled="loading11 || disabled" :loading="loading11" large class="spust" block>Izhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('hotel','izh1_stalni_dvig', 55)" :disabled="loading12 || disabled" :loading="loading12" large class="stalni_dvig" block>Izhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
                </v-layout>

                <div style="min-height:10px;"></div>

                <v-layout row wrap  justify-center fill-height>
                    <!-- RPI 3 = depandansa (vhod, izhod) -->
                    <v-flex xs12 sm12 md5 lg5 xl4>
                        <v-card>
                                <v-card-title>
                                    <v-icon left>mdi-car</v-icon>
                                            <span class="subtitle-1 font-weight-regular application--card--title">Depandansa (dvig - spust zapornic)</span>
                                            <v-spacer></v-spacer>
                                        <v-btn icon @click="toggleCardContent('card4')">
                                            <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                        </v-btn>
                                </v-card-title>
                                <v-divider v-show="card4"></v-divider>
                                <v-card-text v-show="card4">
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('depandansa','vh1_dvig', 58)" :disabled="loading19 || disabled" :loading="loading19" large class="dvig" block>Vhod dvig</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('depandansa','vh1_spust', 58)" :disabled="loading20 || disabled" :loading="loading20" large class="spust" block>Vhod spust</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('depandansa','vh1_stalni_dvig', 58)" :disabled="loading21 || disabled" :loading="loading21" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                    </v-flex>

                    <!-- RPI 5 KAMP CENTER -->
                    <v-flex xs12 sm12 md5 lg5 xl4>
                        <v-card>
                                <v-card-title>
                                    <v-icon left>mdi-car</v-icon>
                                            <span class="subtitle-1 font-weight-regular application--card--title">Kamp center (dvig - spust zapornic)</span>
                                            <v-spacer></v-spacer>
                                        <v-btn icon @click="toggleCardContent('card5')">
                                            <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                        </v-btn>
                                </v-card-title>
                                <v-divider v-show="card5"></v-divider>
                                <v-card-text v-show="card5">
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('kamp_center','vh1_dvig',59)" :disabled="loading22 || disabled" :loading="loading22" large class="dvig" block>Vhod dvig</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('kamp_center','vh1_spust',59)" :disabled="loading23 || disabled" :loading="loading23" large class="spust" block>Vhod spust</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <v-container grid-list-xs>
                                        <v-layout row wrap align-center justify-center>
                                            <v-flex xs12 md12 lg12>
                                                <v-btn @click="send('kamp_center','vh1_stalni_dvig',59)" :disabled="loading24 || disabled" :loading="loading24" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                    </v-flex>
                </v-layout>

                <div style="min-height:10px;"></div>

                <v-layout row wrap align-center  justify-center fill-height>
                    <!-- RPI 4 = kamp (vhod, izhod) -->
                    <v-flex xs12 sm12 md5 lg5 xl4>
                        <v-card>
                            <v-card-title>
                                <v-icon left>mdi-car</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Kamp (dvig - spust zapornic)</span>
                                        <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card3')">
                                        <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','vh1_dvig',52)" :disabled="loading13 || disabled" :loading="loading13" large class="dvig" block><v-icon left></v-icon>Vhod dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','vh1_spust',52)" :disabled="loading14 || disabled" :loading="loading14" large class="spust" block>Vhod spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','vh1_stalni_dvig',52)" :disabled="loading15 || disabled" :loading="loading15" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','izh1_dvig',53)" :disabled="loading16 || disabled" :loading="loading16" large class="dvig" block><v-icon left></v-icon>Izhod dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','izh1_spust',53)" :disabled="loading17 || disabled" :loading="loading17" large class="spust" block>Izhod spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('kamp','izh1_stalni_dvig',53)" :disabled="loading18 || disabled" :loading="loading18" large class="stalni_dvig" block>Izhod stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex  xs12 sm12 md5 lg5 xl4>&nbsp;</v-flex>
                </v-layout>
        </v-container>
    </div>
</template>

<script>

export default {

    data: () => ({
        
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        loading7: false,
        loading8: false,
        loading9: false,
        loading10: false,
        loading11: false,
        loading12: false,
        loading13: false,
        loading14: false,
        loading15: false,
        loading16: false,
        loading17: false,
        loading18: false,
        loading19: false,
        loading20: false,
        loading21: false,
        loading22: false,
        loading23: false,
        loading24: false,
        disabled: false
    }),

    watch: {
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },
    },

    methods: {

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }

            if(card === 'card2') {
                this.card2 = !this.card2
            }

            if(card === 'card3') {
                this.card3 = !this.card3
            }

            if(card === 'card4') {
                this.card4 = !this.card4
            }

            if(card === 'card5') {
                this.card5 = !this.card5
            }
        },

        send(rpi, data, device_id) {
            var vm = this
            let payload = {
                topic:"parking-barriers/" + rpi,
                location: rpi,
                device_id: device_id,
                application_uuid: process.env.VUE_APP_UUID,
                payload: data
            };

            let snackbarText = ''

            if(data == 'vh1_dvig') {
                this.loading1 = true
                snackbarText = "Dvig vhodne zapornice je uspel."
            }

            if(data == 'vh1_spust') {
                this.loading2 = true
                snackbarText = "Spust vhodne zapornice je uspel."
            }

            if(data == 'vh1_stalni_dvig') {
                this.loading3 = true
                snackbarText = "Stalni dvig vhodne zapornice je uspel."
            }

            if(data == 'izh1_dvig') {
                this.loading1 = true
                snackbarText = "Dvig izhodne zapornice je uspel."
            }

            if(data == 'izh1_spust') {
                this.loading2 = true
                snackbarText = "Spust izhodne zapornice je uspel."
            }

            if(data == 'izh1_stalni_dvig') {
                this.loading3 = true
                snackbarText = "Stalni izhodne vhodne zapornice je uspel."
            }


            this.disabled = true;
            vm.$store.dispatch('PUBLISH', payload)
            .then(response => {
                window.console.log(response)
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', snackbarText)
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .catch(error => {
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', "Pri upravljanju zapornice je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .finally(() => {
                this.loading1 = false
                this.loading2 = false
                this.loading3 = false
                setTimeout(() => {
                    vm.disabled = false;
                }, 4000)
            })

            //window.console.log(payload)
        }
    },


    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    }
}

</script>

<style scoped>
    .dvig {
        background: #4ba36b !important;
        color: #fff;
    }

    .spust {
        background: #e79e42 !important;
        color: #fff;
    }

    .stalni_dvig {
        background: #ce5959 !important;
        color: #fff;
    }
</style>
